import { InputTheme } from 'components/common/types/Input.types';
import { LabelProps } from './Label.types';

const LabelStyles: Record<InputTheme, { base: string; requiredText: string; requiredTextClass: string }> = {
  [InputTheme.LIGHT]: {
    base: 'sub-header-base text-neutral-950-opacity-90 mb-1 flex justify-between',
    requiredText: 'Required',
    requiredTextClass: 'text-primary-600 font-normal',
  },
  [InputTheme.DARK]: {
    base: 'sub-header-base text-neutral-950-opacity-90 mb-1 flex justify-between',
    requiredText: 'Required',
    requiredTextClass: 'text-primary-600 font-normal',
  },
  [InputTheme.FLAT_GRAY]: {
    base: 'body-sm text-essential-secondary flex gap-0.5',
    requiredText: '*',
    requiredTextClass: 'body-sm text-essential-secondary',
  },
};

export const Label: React.FC<LabelProps> = ({ id, dataTestId, label, isRequired, theme = InputTheme.LIGHT }) => {
  return (
    <label htmlFor={id} data-test-id={dataTestId} className={LabelStyles[theme].base}>
      {label}
      {isRequired ? (
        <div
          className={LabelStyles[theme].requiredTextClass}
          data-test-id={`required-indicator-for-${label.replaceAll(' ', '-').toLowerCase()}`}
        >
          {LabelStyles[theme].requiredText}
        </div>
      ) : null}
    </label>
  );
};
