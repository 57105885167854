import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import { isArray, isNumber } from 'lodash';
import excludeSvg from 'assets/icons/exclude.svg';
import {
  ButtonThemes,
  SectionButtonProps,
  SectionButtonRounded,
  SectionButtonShadow,
  SectionButtonTheme,
} from './SectionButton.types';
import SectionButtonMenu from './SectionButtonMenu';
import SectionButtonChipList from './SectionButtonChipList';
import SectionButtonChip from './SectionButtonChip';
import { ChipColors } from './SectionButtonChip/SectionButtonChip.types';

const SectionButton: React.FC<PropsWithChildren<SectionButtonProps>> = ({
  theme,
  dataTestId,
  icon,
  label,
  children,
  isActive,
  isDisabled,
  isRequired,
  selected,
  onClick,
  onClear,
  areChipsAutoCollapsed = false,
  rounded = SectionButtonRounded.DEFAULT,
  shadow,
  isValueTruncated,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [areChipsCollapsed, setAreChipsCollapsed] = useState(areChipsAutoCollapsed);

  const chipsLength = useMemo((): number | { included: number; excluded: number } => {
    if (!selected) return 0;

    if (isArray(selected)) return selected.length;

    let nonCategorisedCount = 0;
    const includeExcludeCount = { included: 0, excluded: 0 };

    Object.keys(selected).forEach((category) => {
      const chips = selected[category];

      if (isArray(chips)) nonCategorisedCount += chips.length;
      else {
        const { included, excluded } = chips;

        if (included) includeExcludeCount.included += included.length;
        if (excluded) includeExcludeCount.excluded += excluded.length;
      }
    });

    return nonCategorisedCount !== 0 ? nonCategorisedCount : includeExcludeCount;
  }, [selected]);

  useEffect(() => {
    setAreChipsCollapsed(areChipsAutoCollapsed);
  }, [areChipsAutoCollapsed]);

  const buttonContent = useMemo(() => {
    if (isNumber(chipsLength) && areChipsCollapsed)
      return <SectionButtonChip label="Selections" value={chipsLength.toString()} />;

    if (!isNumber(chipsLength) && areChipsCollapsed)
      return (
        <div className="flex flex-col align-middle items-end gap-1">
          <SectionButtonChip label="Selections" value={`${chipsLength.included} included`} />
          <SectionButtonChip
            label="Selections"
            value={`${chipsLength.excluded} excluded`}
            labelStyle={ChipColors.LIGHT_GREY}
            valueStyle={ChipColors.DARK_GREY}
            icon={excludeSvg}
          />
        </div>
      );

    if (selected) return <SectionButtonChipList selected={selected} isValueTruncated={isValueTruncated} />;

    return children;
  }, [selected, chipsLength, areChipsCollapsed, children]);

  const shadowStyle =
    shadow || (theme === SectionButtonTheme.SOLID ? SectionButtonShadow.DEFAULT : SectionButtonShadow.NONE);

  return (
    <button
      data-test-id={dataTestId}
      className={cx(
        `relative flex p-2 gap-1 border w-full disabled:bg-neutral-200 disabled:cursor-not-allowed`,
        rounded,
        shadowStyle,
        {
          'ring-1 border-primary-600 ring-primary-600 bg-primary-50': isActive,
          [ButtonThemes[theme].inactive]: !isActive,
          [ButtonThemes[theme].buttonContainer]: true,
        },
      )}
      type="button"
      disabled={isDisabled}
      onClick={onClick}
    >
      <div className="flex flex-row items-center gap-1 py-0.5">
        <SVG
          className={cx('fill-current w-4 h-4', {
            'text-neutral-500': isDisabled,
            [ButtonThemes[theme].iconColor]: !isDisabled,
          })}
          src={icon}
        />
        <span
          className={cx('sub-header-base whitespace-nowrap', {
            'text-neutral-500': isDisabled,
            [ButtonThemes[theme].textColor]: !isDisabled,
          })}
        >
          {label}
        </span>
      </div>
      <div className="flex flex-1 justify-end">{buttonContent}</div>
      {!selected && isRequired ? (
        <div className="flex h-full self-center justify-end">
          <span className="text-primary">Required</span>
        </div>
      ) : null}
      {onClear ? (
        <SectionButtonMenu
          onClear={onClear}
          onToggle={() => setAreChipsCollapsed(!areChipsCollapsed)}
          isToggleDisabled={
            (isNumber(chipsLength) && chipsLength <= 1) ||
            (!isNumber(chipsLength) && chipsLength.included <= 1 && chipsLength.excluded <= 1)
          }
          isCollapsed={areChipsCollapsed}
        />
      ) : null}
    </button>
  );
};

export default SectionButton;
