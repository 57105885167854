import PropTypes from 'prop-types';
import cx from 'classnames';
import DealHeaderUnified from 'components/pages/DealWithLines/common/DealHeaderUnified/DealHeaderUnified';
import { useSelector } from 'react-redux';
import { getIsAdServer } from 'store/publisher/selectors';

const DealWithRightSidebar = ({ sidebar, header, leftContent, rightContent }) => {
  const isAdServerMarket = useSelector(getIsAdServer);

  return (
    <div className="h-screen grid grid-rows-[auto_1fr]">
      {isAdServerMarket ? <DealHeaderUnified /> : null}
      <main className="grid grid-cols-[1fr_var(--right-sidebar-width)] overflow-hidden">
        <div className="grid gap-y-5 grid-cols-[var(--deal-nav-panel-width)_1fr] grid-rows-[var(--header-height)_1fr] overflow-auto">
          {isAdServerMarket ? null : <div className="col-span-full">{header}</div>}
          <div className="sticky top-5 z-1 place-self-start">{leftContent}</div>
          <div className={cx('px-8', { 'mt-5': isAdServerMarket })}>{rightContent}</div>
        </div>
        <div className="bg-neutral-100 border-l border-neutral-300 overflow-auto">{sidebar}</div>
      </main>
    </div>
  );
};

DealWithRightSidebar.propTypes = {
  sidebar: PropTypes.element.isRequired,
  header: PropTypes.element.isRequired,
  leftContent: PropTypes.element.isRequired,
  rightContent: PropTypes.element.isRequired,
};

export default DealWithRightSidebar;
