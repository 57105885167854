import { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import { changeDealType } from 'store/dealManagement/actions';
import Auth from 'modules/Auth';
import { getDefaultSweep } from 'utils/deal';
import { PermissionsEnum } from 'consts/permissions';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';

import Popup, { PopupAlign } from 'components/patterns/Popup';
import { AuctionModel, DealType, DealStatus } from 'components/common/types/Deal.types';

import { isReadOnly } from 'utils/isReadOnly';
import { getIsReseller } from 'store/publisher/selectors';
import getDealTypes from './getDealTypes';
import AuctionPriority from './AuctionPriority';

const DealTypes = () => {
  const dispatch = useDispatch();
  const { type: publisherType } = useSelector((state) => state.publisher.configuration);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const containerRef = useRef();
  const isReseller = useSelector(getIsReseller);
  const isNewDeal = useSelector((state) => state.dealManagement.isNewDeal);
  const isEditingDisabled = useSelector((state) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state) => state.dealManagement.commonDeal.bookingStatusCode);
  const auctionModel = useSelector((state) => state.dealManagement.commonDeal.auctionModel);
  const dealType = useSelector((state) => state.dealManagement.commonDeal.dealType);

  const isLive = bookingStatusCode === DealStatus.LIVE;
  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled);
  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);

  const handleOnClick = ({ target }) => {
    dispatch(changeDealType(target.value, hasNonGuaranteedExtendedTargetEnabled));
    dispatch(
      changeDealCurrentLineData({
        sweep: getDefaultSweep(target.value, hasNonGuaranteedExtendedTargetEnabled),
      }),
    );

    if (isPopupOpen) setIsPopupOpen(false);
  };

  const getAuctionVisibility = (code) =>
    Auth.hasPermission(PermissionsEnum.DEAL_AUCTION_MODEL_VIEW) && code === DealType.NON_GUARANTEED_FLOOR_PRICE;

  const buttonActiveStyle = (code) => {
    return dealType === code
      ? 'bg-primary-600-opacity-10 text-primary-600'
      : 'bg-neutral-950-opacity-5 text-neutral-600 hover:bg-neutral-950-opacity-10';
  };

  const buttonLayoutStyle = (code) => {
    return getAuctionVisibility(code) ? 'grid grid-cols-10' : 'flex';
  };

  useEffect(() => {
    if (isReseller) {
      dispatch(changeDealType(DealType.NON_GUARANTEED_FLOOR_PRICE));
    }
  }, [isReseller]);

  return (
    <div className="grid grid-cols-3 gap-x-4" ref={containerRef}>
      {Auth.hasPermission(PermissionsEnum.DEAL_TYPE_VIEW) &&
        getDealTypes(dealType, isNewDeal, publisherType).map(({ name, code }) => (
          <div
            key={code}
            className={cx('p-1 rounded-lg', buttonActiveStyle(code), buttonLayoutStyle(code), {
              'opacity-80 disabled:text-neutral-100-opacity-70': readOnly,
            })}
            data-test-id={`deal-types-container-${code.toLowerCase()}`}
          >
            <button
              data-test-id={code}
              key={code}
              type="button"
              value={code}
              onClick={handleOnClick}
              disabled={readOnly}
              className={cx('col-span-9 place-self-center w-full h-8 cursor-pointer sub-header-base', {
                'cursor-not-allowed pointer-events-none': readOnly,
              })}
            >
              {name}
            </button>
            {getAuctionVisibility(code) ? (
              <Popup
                parentElementRef={containerRef.current}
                isOpen={isPopupOpen}
                alignContent={PopupAlign.CENTER}
                onClickOutside={() => setIsPopupOpen(false)}
                content={<AuctionPriority />}
              >
                <button
                  type="button"
                  disabled={readOnly || isLive || dealType !== DealType.NON_GUARANTEED_FLOOR_PRICE}
                  onClick={() => setIsPopupOpen((prevState) => !prevState)}
                  className={cx('place-self-end w-8 h-8 rounded-md text-center sub-header-base', {
                    'bg-primary text-neutral-50': dealType === DealType.NON_GUARANTEED_FLOOR_PRICE,
                    'bg-neutral-50 text-neutral-600':
                      readOnly || isLive || dealType !== DealType.NON_GUARANTEED_FLOOR_PRICE,
                  })}
                >
                  {auctionModel === AuctionModel.FIRST_PRICE ? '1st' : '2nd'}
                </button>
              </Popup>
            ) : null}
          </div>
        ))}
    </div>
  );
};

export default DealTypes;
