import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DealStatus } from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';
import { changeFormParams, changeIsNewDeal, DealManagement, resetState } from './reducer';

export const useDealManagement = ({ isNewDeal = false }): DealManagement => {
  const dispatch = useDispatch();
  const dealManagementState = useSelector((state: Store) => state.dealManagement);
  const { type: publisherType } = useSelector((storeState: Store) => storeState.publisher.configuration);

  const {
    commonDeal: { bookingStatusCode, dealId },
    isDuplicated,
  } = dealManagementState;

  useEffect(() => {
    if (isNewDeal && !isDuplicated) {
      dispatch(changeIsNewDeal(publisherType));
    }
  }, [isNewDeal]);

  useEffect(() => {
    if (dealId) {
      dispatch(changeFormParams({ isForecastedAllocation: bookingStatusCode === DealStatus.PENDING_APPROVAL }));
    }
  }, [dealId, bookingStatusCode]);

  useEffect(
    () => () => {
      dispatch(resetState());
    },
    [],
  );

  return dealManagementState;
};
