import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import { CancelFunctions } from 'components/common/types';
import { getDefaultSweep } from 'utils/deal';
import { getDeal } from 'modules/api/adsDeal';
import { getPoiData } from 'modules/api/lookups';

import { transformDealLineDetails } from 'components/pages/DealWithLines/AdsDealLines/transformDealDetails';
import { fetchDealBids, getDealLinesDetails } from 'modules/api/deal';
import { transformMultiLineDealDetails } from 'components/pages/DealWithLines/DealLines/transformDealDetails';
import { PlannerPoiWithPoints } from 'components/common/types/Planner.types';

import {
  CommonDeal,
  DealPriority,
  DealType,
  Programmatic,
  ProgrammaticCampaign,
  SelectedDay,
} from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';
import { DealConfigStore } from 'store/dealConfig/dealConfig.types';
import {
  BackupFormData,
  startLoading,
  stopLoading,
  changeDealCurrentLineData,
  changeDealData,
  changeDealDetails,
  changeOnlyProgrammaticDealData,
  clearForm,
  updatePlannerPoi,
} from 'store/dealManagement/reducer';

export const changeDealType =
  (dealType: DealType, hasNonGuaranteedExtendedTargetEnabled: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    const isGuaranteed = dealType === DealType.GUARANTEED;

    dispatch(
      changeDealCurrentLineData({
        sweep: getDefaultSweep(dealType, hasNonGuaranteedExtendedTargetEnabled),
      }),
    );

    dispatch(
      changeDealData({
        dealType,
        dealPriority: isGuaranteed ? DealPriority.PREFERRED : DealPriority.STANDARD,
      }),
    );
  };

export const changeDealInfo =
  (dealInfo: Partial<CommonDeal>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(changeDealData(dealInfo));
  };

export const changeProgrammaticDealInfo =
  (dealInfo: Partial<ProgrammaticCampaign>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(changeOnlyProgrammaticDealData(dealInfo));
  };

export const changeSelectedDays =
  (selectedDays: { [key: string]: SelectedDay }) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(changeDealCurrentLineData({ selectedDays }));
  };

const fetchAndUpdatePlannerPoiPoints =
  (cancelFunctions: CancelFunctions) =>
  async (dispatch: Dispatch, getState: () => Store): Promise<void> => {
    const { plannerPoi } = getState().dealManagement.commonDeal.currentLine.proximity;

    if (plannerPoi.length === 0) return;

    const plannerPoisWithoutPoints = plannerPoi.filter((plannerPoiElement) => plannerPoiElement.poiPoints.length === 0);

    const emptyPoiCodeNames = plannerPoisWithoutPoints.map(
      (plannerPoiElement) => `${plannerPoiElement.code}-${plannerPoiElement.name}`,
    );

    if (emptyPoiCodeNames.length === 0) return;

    const allPoiPoints = await getPoiData(cancelFunctions, emptyPoiCodeNames);
    const poiPointsCategoryMap = allPoiPoints.reduce((resultMap, currentPlannerPoi) => {
      const poiCategoryName = currentPlannerPoi.properties.categoryName;

      if (!resultMap.has(poiCategoryName)) {
        const poiCategory = plannerPoisWithoutPoints.find(
          (poi) => poi.categoryName === currentPlannerPoi.properties.categoryName,
        );

        if (poiCategory) {
          resultMap.set(poiCategoryName, {
            code: poiCategory.code,
            visibility: poiCategory.visibility,
            poiPoints: [],
          });
        }
      }

      resultMap.get(poiCategoryName)!.poiPoints.push(currentPlannerPoi);

      return resultMap;
    }, new Map<string, Pick<PlannerPoiWithPoints, 'poiPoints' | 'code' | 'visibility'>>());

    dispatch(updatePlannerPoi(Array.from(poiPointsCategoryMap.values())));
  };

export const getDealDetails =
  (
    internalId: string,
    cancelFunctions: CancelFunctions,
    navigate: NavigateFunction,
    config: DealConfigStore,
    hasAdsDealLevelCPMEnabled: boolean,
    hasNonGuaranteedExtendedTargetEnabled: boolean,
    isAdServerMarket: boolean,
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      if (isAdServerMarket) {
        const dealDetails = await getDeal(internalId, cancelFunctions);

        try {
          const bids = await fetchDealBids(cancelFunctions, internalId);

          if (bids) {
            dealDetails.bids = bids;
          }
        } catch {} // eslint-disable-line no-empty

        dispatch(
          changeDealDetails({
            ...(transformDealLineDetails({
              ...dealDetails,
              hasNonGuaranteedExtendedTargetEnabled,
            }) as {
              backupFormData: BackupFormData;
              commonDeal: Partial<CommonDeal>;
              programmatic: Programmatic;
            }),
            hasAdsDealLevelCPMEnabled,
            isAdServerMarket,
          }),
        );
      } else {
        const dealDetails = await getDealLinesDetails(internalId, cancelFunctions);

        try {
          const bids = await fetchDealBids(cancelFunctions, dealDetails.dealId);

          if (bids) {
            dealDetails.bids = bids;
          }
        } catch {} // eslint-disable-line no-empty

        dispatch(
          changeDealDetails({
            ...(transformMultiLineDealDetails(dealDetails, config, hasNonGuaranteedExtendedTargetEnabled) as {
              backupFormData: BackupFormData;
              commonDeal: Partial<CommonDeal>;
              programmatic: Programmatic;
              hasNonGuaranteedExtendedTargetEnabled: boolean;
            }),
            hasAdsDealLevelCPMEnabled,
            isAdServerMarket,
          }),
        );
      }

      dispatch(startLoading());
      await dispatch(fetchAndUpdatePlannerPoiPoints(cancelFunctions));
      dispatch(stopLoading());
    } catch (error) {
      dispatch(clearForm());
      navigate('/deal');
    }
  };
