import { useState, useLayoutEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Line } from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';

const MAX_WIDTH_LINE_NAME_CHIP = 120;

export type UsePlannerHeaderLineContainer = {
  headerContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  displayDealLinesDropdown: boolean;
  displayedLines: Line[];
  allLinesSaved?: boolean;
  hasReachedMaxLines?: boolean;
};

export const usePlannerHeaderLineContainer = (): UsePlannerHeaderLineContainer => {
  const backupFormDataLines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);
  const currentLine = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine);
  const maxAllowedLines = useSelector((state: Store) => state.dealConfig.maxAllowedLines);

  const lines = useMemo(() => {
    if (!backupFormDataLines.length) {
      return [currentLine] as Line[];
    }

    const currentLineInLines = backupFormDataLines.find((line) => line.id === currentLine?.id) ?? null;
    if (!currentLineInLines) {
      return (backupFormDataLines as Line[]).concat([currentLine]);
    }

    return backupFormDataLines;
  }, [backupFormDataLines, currentLine]);

  const [displayDealLinesDropdown, setDisplayDealLinesDropdown] = useState<boolean>(false);
  const [displayedLines, setDisplayedLines] = useState<Line[]>(lines);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const allLinesSaved = useMemo(() => lines.every((line) => !!line.lineId), [lines]);
  const hasReachedMaxLines = useMemo(() => lines.length >= maxAllowedLines, [lines, maxAllowedLines]);

  const headerContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useLayoutEffect(() => {
    if (!headerContainerRef.current) return;
    const headerWidth = headerContainerRef.current.clientWidth;
    const isOverflowing = lines.length * MAX_WIDTH_LINE_NAME_CHIP >= headerWidth;

    if (isOverflowing) {
      const maxLinesForViewport = Math.floor(headerWidth / MAX_WIDTH_LINE_NAME_CHIP);
      const currentLineIndex = lines.findIndex((line) => line.name === currentLine.name);

      const numberOfLinesToRemove = lines.length - maxLinesForViewport;
      const startIndex = currentLineIndex < numberOfLinesToRemove ? lines.length - numberOfLinesToRemove : 0;

      const isTrimmingRequired = lines.length - currentLineIndex > maxLinesForViewport;

      if (isTrimmingRequired) {
        const copyOfLines = cloneDeep(lines);
        const updatedLines = copyOfLines.slice(currentLineIndex, currentLineIndex + maxLinesForViewport);
        setDisplayedLines(updatedLines);
      } else {
        const copyOfLines = cloneDeep(lines);
        copyOfLines.splice(startIndex, numberOfLinesToRemove);
        setDisplayedLines(copyOfLines);
      }

      setDisplayDealLinesDropdown(true);
    } else {
      setDisplayedLines(lines);
      setDisplayDealLinesDropdown(false);
    }
  }, [lines, windowWidth, currentLine.name]);

  useLayoutEffect(() => {
    const handleResize = (): void => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    headerContainerRef,
    displayDealLinesDropdown,
    displayedLines,
    allLinesSaved,
    hasReachedMaxLines,
  };
};
