import { InputSize, InputTheme } from 'components/common/types/Input.types';
import { BaseSelectOptionProps, BaseSelectProps } from './BaseSelect.types';
import BaseSelectWrapper from './BaseSelectWrapper';
import BaseSelectOption from './BaseSelectOption';

const BaseSelect = ({
  defaultOptions,
  options,
  name,
  placeholder,
  selectedValue,
  onSelect,
  dataTestId,
  closeMenuOnSelect,
  isClearable,
  isDisabled,
  isSearchable,
  isSelectionVisible,
  isPlaceholderVisible,
  menuPosition,
  menuPlacement,
  shadow,
  shape,
  padding,
  maxMenuHeight,
  isMultiple = false,
  customComponents,
  withPortal,
  theme = InputTheme.LIGHT,
  size = InputSize.MEDIUM,
}: BaseSelectProps): React.ReactElement => {
  return (
    <BaseSelectWrapper
      defaultOptions={defaultOptions}
      options={options}
      name={name}
      placeholder={placeholder}
      selectedValue={selectedValue}
      onSelect={onSelect}
      dataTestId={dataTestId}
      isClearable={isClearable}
      isSelectionVisible={isSelectionVisible}
      maxMenuHeight={maxMenuHeight}
      padding={padding}
      customComponents={{
        Option: ({ isSelected, innerRef, innerProps, children }: BaseSelectOptionProps) => {
          return (
            <BaseSelectOption
              isMultiple={isMultiple}
              isSelected={isSelected}
              innerRef={innerRef}
              innerProps={innerProps}
            >
              {children}
            </BaseSelectOption>
          );
        },
        ...customComponents,
      }}
      closeMenuOnSelect={closeMenuOnSelect}
      isMultiple={isMultiple}
      isDisabled={isDisabled}
      isPlaceholderVisible={isPlaceholderVisible}
      menuPosition={menuPosition}
      menuPlacement={menuPlacement}
      shadow={shadow}
      shape={shape}
      withPortal={withPortal}
      isSearchable={isSearchable}
      theme={theme}
      size={size}
    />
  );
};

export default BaseSelect;
