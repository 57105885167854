import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getMetaData } from 'modules/api/deal';
import { getDealConfig } from 'modules/api/adsDeal';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import DealWithRightSidebar from 'components/common/layout/DealWithRightSidebar';
import Panel from 'components/common/Deal/Panel';

import { getDealDetails } from 'store/dealManagement/actions';
import { changeMeta, createNewLineWithUniqueName } from 'store/dealManagement/reducer';
import { useCheckAvailability } from 'components/common/Deal/hooks/useCheckAvailability';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';
import { getResellerDealMediaOwners } from 'store/reseller/selectors';
import multiPublisherRequest from 'modules/api/multiPublisherRequest';
import { updateDealConfig } from 'store/dealConfig/reducer';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import RightSidebar from '../common/RightSidebar';
import DealHeader from '../common/DealHeader';
import Main from './Main';

const AdsDealLines = () => {
  const { internalId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasAdsDealLevelCPMEnabled = useHasFeatureAccess(FeatureFlags.ADS_DEAL_LEVEL_CPM);
  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);
  const cancelFunctions = useCancelRequest();
  const isAdServerMarket = useSelector(getIsAdServer);
  const isReseller = useSelector(getIsReseller);
  const resellerMediaOwners = useSelector(getResellerDealMediaOwners);
  const marketId = useSelector((state) => state.publisher.configuration.marketId);
  const dealConfig = useSelector((state) => state.dealConfig);
  const lines = useSelector((state) => state.dealManagement.backupFormData.lines);
  const isNewDeal = useSelector((state) => state.dealManagement.isNewDeal);

  useCheckAvailability();

  useEffect(() => {
    const getMeta = async () => {
      try {
        let metaData;
        if (isReseller) {
          const result = await multiPublisherRequest(resellerMediaOwners, getMetaData, {
            cancelFunctions: {},
          });
          metaData = result.reduce(
            (acc, cur) => {
              acc.poi.attributes = [...acc.poi.attributes, ...cur.poi.attributes];
              acc.poi.datasources = [...acc.poi.datasources, ...cur.poi.datasources];
              acc.productCategoryGroups = [...acc.productCategoryGroups, ...cur.productCategoryGroups];
              return acc;
            },
            { poi: { attributes: [], datasources: [] }, productCategoryGroups: [] },
          );
        } else {
          metaData = await getMetaData({ cancelFunctions });
        }
        dispatch(changeMeta(metaData));
      } catch {} // eslint-disable-line no-empty
    };

    getMeta();
  }, []);

  useEffect(() => {
    const getDealConfigData = async () => {
      try {
        const config = await getDealConfig(marketId, cancelFunctions);

        dispatch(updateDealConfig({ ...config, isAdServer: true }));
      } catch {
        // eslint-disable-next-line no-console
        console.error('Failed fetching deal config for the adserver market');
      }
    };

    if (!dealConfig.isDealConfigFetched) {
      getDealConfigData();
    }
  }, [dealConfig.isDealConfigFetched]);

  useEffect(() => {
    if (internalId && dealConfig.isDealConfigFetched) {
      dispatch(
        getDealDetails(
          internalId,
          cancelFunctions,
          navigate,
          dealConfig,
          hasAdsDealLevelCPMEnabled,
          hasNonGuaranteedExtendedTargetEnabled,
          isAdServerMarket,
        ),
      );
    }
  }, [internalId, cancelFunctions, dealConfig.isDealConfigFetched]);

  useEffect(() => {
    if (isNewDeal && !lines.length) {
      dispatch(createNewLineWithUniqueName());
    }
  }, [isNewDeal, lines]);

  return (
    <DealWithRightSidebar
      sidebar={<RightSidebar />}
      header={<DealHeader />}
      leftContent={<Panel />}
      rightContent={<Main />}
    />
  );
};

export default AdsDealLines;
