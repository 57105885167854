import queryString from 'query-string';
import { URLS, HTTP_METHODS } from 'modules/api/constants';
import createRequest from 'modules/api/request';
import { transformDealIds, transformToCodeName, transformSalesPerson, transformSalesTeam } from 'utils/apiAdapters';
import { transformPoiRawData } from 'utils/transformPoiRawData';

export const getLookupData = async ({
  cancelFunctions,
  lookupURLName,
  query: lookupName,
  lookupCode = '',
  proximityMode = false,
  environmentCodes = null,
  cancelable = true,
  envId = null,
}) => {
  const param = {
    lookupName,
  };

  if (lookupCode) {
    param.lookupCode = lookupCode;
  }

  if (proximityMode) {
    param.proximityMode = proximityMode;
  }

  if (environmentCodes && environmentCodes.length > 0) {
    param.environmentCodes = environmentCodes;
  }

  if (lookupURLName === 'productFormat') {
    param.digitalOnly = true;
  }

  const { send, cancel } = createRequest({
    url: URLS().LOOKUP_API(lookupURLName),
    data: param,
    method: HTTP_METHODS.POST,
    showLoader: lookupName === '' && !lookupCode,
    cancelable,
    ...(envId ? { envId } : false),
  });

  if (cancelFunctions.LOOKUP_API) cancelFunctions.LOOKUP_API();
  cancelFunctions.LOOKUP_API = cancel;

  const result = await send();

  if (lookupURLName === 'advertiser') {
    return result.data.advertisers;
  }

  return result.data.lookupData;
};

export const getBrandLookupData = async (cancelFunctions, lookupName, advertiserCode) => {
  const param = {};

  if (lookupName) {
    param.lookupName = lookupName;
  }

  if (advertiserCode) {
    param.advertiserCode = advertiserCode;
  }

  const { send, cancel } = createRequest({
    url: URLS().BRAND_LOOKUP(),
    data: param,
    method: HTTP_METHODS.POST,
    showLoader: false,
  });

  if (cancelFunctions.BRAND_LOOKUP) cancelFunctions.BRAND_LOOKUP();
  cancelFunctions.BRAND_LOOKUP = cancel;

  const result = await send();

  return result.data.brands;
};

export const getPoiList = async (cancelFunctions) => {
  const { send, cancel } = createRequest({
    url: URLS().POI_LIST(),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.POI_LIST) cancelFunctions.POI_LIST();
  cancelFunctions.POI_LIST = cancel;

  const result = await send();

  return result.data;
};

export const getPoiData = async (cancelFunctions, poiList) => {
  const { send, cancel } = createRequest({
    url: URLS().POI_DATA(),
    method: HTTP_METHODS.POST,
    data: { poiList },
  });

  if (cancelFunctions.POI_DATA) cancelFunctions.POI_DATA();
  cancelFunctions.POI_DATA = cancel;

  const result = await send();

  return transformPoiRawData(result.data?.features ?? []);
};

export const getPOILookupData = async (cancelFunctions, lookupName, attributeCode, dataSourceCode) => {
  const reqParam = {};
  if (lookupName) {
    reqParam.lookupName = lookupName;
  }
  if (attributeCode) {
    reqParam.attributeCode = attributeCode;
  }
  if (dataSourceCode) {
    reqParam.dataSourceCode = dataSourceCode;
  }

  const { send, cancel } = createRequest({
    url: URLS().POI_LOOKUP(),
    method: HTTP_METHODS.POST,
    data: reqParam,
    showLoader: false,
  });

  if (cancelFunctions.POI_LOOKUP) cancelFunctions.POI_LOOKUP();
  cancelFunctions.POI_LOOKUP = cancel;

  const result = await send();

  return result.data.lookupData;
};

export const getAverageSegmentScoresPerFrame = async (cancelFunctions, frameIds, segmentIds, startDate, endDate) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_AVERAGE_SEGMENT_SCORES_PER_FRAME(),
    method: HTTP_METHODS.POST,
    data: { frames: frameIds, segments: segmentIds, startDate, endDate },
  });

  if (cancelFunctions.FETCH_AVERAGE_SEGMENT_SCORES_PER_FRAME) cancelFunctions.FETCH_AVERAGE_SEGMENT_SCORES_PER_FRAME();
  cancelFunctions.FETCH_AVERAGE_SEGMENT_SCORES_PER_FRAME = cancel;

  const result = await send();

  return result.data;
};

export const getTagData = async ({
  cancelFunctions,
  lookupURLName,
  environmentCodes = [],
  cancelable = true,
  envId = null,
}) => {
  const param = {};

  if (environmentCodes && environmentCodes.length > 0) {
    param.environmentCodes = environmentCodes;
  }

  const { send, cancel } = createRequest({
    url: URLS().LOOKUP_API(lookupURLName),
    method: HTTP_METHODS.POST,
    showLoader: false,
    data: param,
    cancelable,
    ...(envId ? { envId } : false),
  });

  if (cancelFunctions.LOOKUP_API) cancelFunctions.LOOKUP_API();
  cancelFunctions.LOOKUP_API = cancel;

  const result = await send();

  return result.data.tagGroups;
};

export const getDealIdLookupData = async ({ cancelFunctions, lookupName, status = null, isAdServerMarket }) => {
  const param = {
    lookupName,
  };

  if (status) {
    param.status = status;
  }

  const { send, cancel } = createRequest({
    url: URLS().DEALID_LOOKUP_API(isAdServerMarket),
    data: param,
    method: HTTP_METHODS.POST,
    showLoader: false,
  });

  if (cancelFunctions.DEALID_LOOKUP_API) cancelFunctions.DEALID_LOOKUP_API();
  cancelFunctions.DEALID_LOOKUP_API = cancel;

  const result = await send();

  return result.data.lookupData;
};

export const getContentDealIdLookupData = async (cancelFunctions, environment, query, dspCode) => {
  const queryParams = queryString.stringify(
    { environment, query, dspCode },
    { skipEmptyString: true, arrayFormat: 'comma' },
  );

  const { send, cancel } = createRequest({
    url: URLS().CONTENT_DEALID_LOOKUP_API(queryParams),
    method: HTTP_METHODS.GET,
    showLoader: false,
  });

  if (cancelFunctions.CONTENT_DEALID_LOOKUP_API) cancelFunctions.CONTENT_DEALID_LOOKUP_API();
  cancelFunctions.CONTENT_DEALID_LOOKUP_API = cancel;

  const result = await send();

  return transformDealIds(result.data);
};

export const getCreativeAdminDealIdLookupData = async (cancelFunctions, environment, query) => {
  const queryParams = queryString.stringify({ environment, query }, { skipEmptyString: true, arrayFormat: 'comma' });

  const { send, cancel } = createRequest({
    url: URLS().CREATIVE_ADMIN_DEALID_LOOKUP_API(queryParams),
    method: HTTP_METHODS.GET,
    showLoader: false,
  });

  if (cancelFunctions.CREATIVE_ADMIN_DEALID_LOOKUP_API) cancelFunctions.CREATIVE_ADMIN_DEALID_LOOKUP_API();
  cancelFunctions.CREATIVE_ADMIN_DEALID_LOOKUP_API = cancel;

  const result = await send();

  return transformDealIds(result.data);
};

export const getCreativeIdsLookupSuggestions = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().CREATIVE_ID_LOOKUP_API(`query=${query}`),
    method: HTTP_METHODS.GET,
    showLoader: false,
  });

  if (cancelFunctions.CREATIVE_ID_LOOKUP_API) cancelFunctions.CREATIVE_ID_LOOKUP_API();
  cancelFunctions.CREATIVE_ID_LOOKUP_API = cancel;

  const result = await send();

  return transformToCodeName(result.data);
};

export const getMarketLookupSuggestions = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().MARKET_LOOKUP_API(`query=${query}`),
    method: HTTP_METHODS.GET,
    showLoader: false,
  });

  if (cancelFunctions.MARKET_LOOKUP_API) cancelFunctions.MARKET_LOOKUP_API();
  cancelFunctions.MARKET_LOOKUP_API = cancel;

  const result = await send();

  return transformToCodeName(result.data);
};

export const getDspLookupSuggestions = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().DSP_LOOKUP_API(`query=${query}`),
    method: HTTP_METHODS.GET,
    showLoader: false,
  });

  if (cancelFunctions.DSP_LOOKUP_API) cancelFunctions.DSP_LOOKUP_API();
  cancelFunctions.DSP_LOOKUP_API = cancel;

  const result = await send();

  return result.data;
};

export const getVenueTaxonomyLookup = async (cancelFunctions) => {
  const { send, cancel } = createRequest({
    url: URLS().LOOKUP_API('venueTaxonomy'),
    method: HTTP_METHODS.GET,
    showLoader: false,
  });

  if (cancelFunctions.LOOKUP_API) cancelFunctions.LOOKUP_API();
  cancelFunctions.LOOKUP_API = cancel;

  const result = await send();

  return result.data.venueTaxonomyList;
};

export const getSalesPersonLookup = async (cancelFunctions, query, pageSize = 10) => {
  const queryParams = queryString.stringify({
    search: `name==*${query.replace(/\s/g, '_')}*`,
    pageSize,
  });

  const { send, cancel } = createRequest({
    url: URLS().SALES_PERSON_LOOKUP_API(queryParams),
    method: HTTP_METHODS.GET,
    showLoader: false,
  });

  if (cancelFunctions.SALES_PERSON_LOOKUP_API) cancelFunctions.SALES_PERSON_LOOKUP_API();
  cancelFunctions.SALES_PERSON_LOOKUP_API = cancel;

  const result = await send();

  return transformSalesPerson(result.data.person_list);
};

export const getSalesTeamLookup = async (cancelFunctions, query) => {
  const queryParams = queryString.stringify({ search: `name==*${query}*` });

  const { send, cancel } = createRequest({
    url: URLS().SALES_TEAM_LOOKUP_API(queryParams),
    method: HTTP_METHODS.GET,
    showLoader: false,
  });

  if (cancelFunctions.SALES_TEAM_LOOKUP_API) cancelFunctions.SALES_TEAM_LOOKUP_API();
  cancelFunctions.SALES_TEAM_LOOKUP_API = cancel;

  const result = await send();

  return transformSalesTeam(result.data.sales_team_list);
};

export const getSegmentOptions = async (cancelFunctions) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_SECONDARY_AUDIENCE_OPTIONS(),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  if (cancelFunctions.FETCH_SECONDARY_AUDIENCE_OPTIONS) cancelFunctions.FETCH_SECONDARY_AUDIENCE_OPTIONS();
  cancelFunctions.FETCH_SECONDARY_AUDIENCE_OPTIONS = cancel;

  const result = await send();

  const segmentOptions = result.data;
  segmentOptions.forEach((dataProvider) => {
    dataProvider.name = dataProvider.value;
    dataProvider.code = dataProvider.value;
  });

  return segmentOptions;
};

export const getSegmentFrameAverageByHour = async (cancelFunctions, payload) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_SECONDARY_AUDIENCE_AVERAGE_BY_SEGMENT_AND_HOUR(),
    data: payload,
    method: HTTP_METHODS.POST,
  });

  if (cancelFunctions.FETCH_SECONDARY_AUDIENCE_AVERAGE_BY_SEGMENT_AND_HOUR)
    cancelFunctions.FETCH_SECONDARY_AUDIENCE_AVERAGE_BY_SEGMENT_AND_HOUR();
  cancelFunctions.FETCH_SECONDARY_AUDIENCE_AVERAGE_BY_SEGMENT_AND_HOUR = cancel;

  const result = await send();

  return result.data;
};

export const getReferenceData = async (cancelFunctions, payload) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_DIRECT_SALES_REFERENCE_DATA(),
    data: payload,
    method: HTTP_METHODS.POST,
  });

  if (cancelFunctions.FETCH_DIRECT_SALES_REFERENCE_DATA) cancelFunctions.FETCH_DIRECT_SALES_REFERENCE_DATA();
  cancelFunctions.FETCH_DIRECT_SALES_REFERENCE_DATA = cancel;

  const result = await send();

  return result.data;
};
