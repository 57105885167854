/* eslint-disable sonarjs/cognitive-complexity */
import { Dsp } from 'components/common/types/Deal.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import usePrevious from 'customHooks/usePrevious';
import { useResellerMediaOwners } from 'customHooks/useResellerMediaOwners';
import { getLookupData } from 'modules/api/lookups';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsReseller } from 'store/publisher/selectors';
import { changeDspsFromMediaOwners, DspFromMediaOwners } from 'store/reseller/reducer';
import { getResellerDealMediaOwners } from 'store/reseller/selectors';
import { FilterDspProps } from './FilterDsp.types';
import { getResellerDSPLookup, useResellerDSPLookup } from './utils';

const FilterDsp: FunctionComponent<FilterDspProps> = ({
  dataTestId = 'dsp-filter',
  isDisabled = false,
  selectedValue,
  placeholder = 'Select DSP',
  onChange,
}) => {
  useResellerMediaOwners();
  const dispatch = useDispatch();
  const isReseller = useSelector(getIsReseller);
  const resellerMediaOwners = useSelector(getResellerDealMediaOwners);
  const previousResellerMediaOwners = usePrevious(resellerMediaOwners);
  const [dsps, setDsps] = useState<Dsp[]>([]);
  const cancelFunctions = useCancelRequest();

  const updateMediaOwners = (mediaOwners: DspFromMediaOwners[]): void => {
    dispatch(changeDspsFromMediaOwners(mediaOwners));
  };

  const loadDSPData = async (_dropdownName?: string, qry?: string): Promise<Dsp[]> => {
    if (!dsps || dsps.length === 0) {
      try {
        const requestArguments = {
          cancelFunctions: isReseller ? {} : cancelFunctions,
          lookupURLName: 'dsp',
          query: '',
        };

        const result = isReseller
          ? await getResellerDSPLookup(resellerMediaOwners, requestArguments, updateMediaOwners)
          : await getLookupData(requestArguments);

        setDsps(result);
        return result;
      } catch {} // eslint-disable-line no-empty
    } else if (qry) {
      return dsps.filter((d) => d.name.toLowerCase().indexOf(qry.toLowerCase()) > -1);
    }

    return [];
  };

  useEffect(() => {
    useResellerDSPLookup(isReseller, dsps, resellerMediaOwners, previousResellerMediaOwners, loadDSPData);
  }, [resellerMediaOwners, isReseller]);

  useEffect(() => {
    loadDSPData();
  }, []);

  return (
    <AsyncSelect
      dataTestId={dataTestId}
      name="dsp"
      loadData={loadDSPData}
      defaultOptions={dsps}
      onSelect={onChange}
      placeholder={placeholder}
      selectedValue={selectedValue && selectedValue.code ? selectedValue : null}
      isDisabled={isDisabled}
      debounceTime={0}
    />
  );
};

export default FilterDsp;
/* eslint-enable sonarjs/cognitive-complexity */
