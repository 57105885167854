import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

import moneySvg from 'assets/icons/money.svg';
import bullhornSvg from 'assets/icons/bullhorn.svg';
import infoSvg from 'assets/icons/info.svg';
import comparisonSvg from 'assets/icons/comparison.svg';
import chevronDownSvg from 'assets/icons/chevron_down.svg';
import editSvg from 'assets/icons/edit.svg';
import userSvg from 'assets/icons/user.svg';
import clipboardSvg from 'assets/icons/clipboard.svg';

import { DEAL_STATUS_BADGE } from 'consts/deal';
import { formatCurrency } from 'modules/I18N';
import { getDealTypeLabel } from 'utils/getDealTypeLabel';
import { getDealDetails } from 'store/dealManagement/actions';
import { toggleDealFormOpen } from 'store/dealManagement/reducer';
import { CardType, CardTheme } from 'components/patterns/Card/Card.types';
import Card from 'components/patterns/Card';
import DealName from 'components/common/Deal/DealName';
import DealTypes from 'components/common/Deal/DealTypes';
import DealInfo from 'components/common/Deal/DealInfo';
import CountdownTimer from 'components/patterns/CountdownTimer';
import Button, { ButtonType, ButtonSize } from 'components/patterns/Button';
import StatusRenderer from 'components/common/StatusRenderer';
import { BadgeSize } from 'components/patterns/Badge';
import { TooltipDirection } from 'components/patterns/Tooltip';
import { links } from 'components/common/Deal/Panel';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { getIsAdServer } from 'store/publisher/selectors';
import AdsDealLevelCpm from './AdsDealLevelCpm';

const Deal = (_, forwardedRef) => {
  const navigate = useNavigate();
  const cancelFunctions = useCancelRequest();
  const dispatch = useDispatch();
  const isDealFormOpen = useSelector((state) => state.dealManagement.isDealFormOpen);
  const config = useSelector((state) => state.dealManagement.config);
  const isCpmCampaignLevel = useSelector((state) => state.dealManagement.isCpmCampaignLevel);
  const dealId = useSelector((state) => state.dealManagement.commonDeal.dealId);
  const bookingStatusCode = useSelector((state) => state.dealManagement.commonDeal.bookingStatusCode);
  const dealName = useSelector((state) => state.dealManagement.commonDeal.dealName);
  const cpm = useSelector((state) => state.dealManagement.commonDeal.cpm);
  const advertiser = useSelector((state) => state.dealManagement.commonDeal.advertiser);
  const dealType = useSelector((state) => state.dealManagement.commonDeal.dealType);
  const expirationDate = useSelector((state) => state.dealManagement.commonDeal.expirationDate);
  const { currencyCode, localeCode } = useSelector((state) => state.publisher.configuration);
  const hasAdsDealLevelCPMEnabled = useHasFeatureAccess(FeatureFlags.ADS_DEAL_LEVEL_CPM);
  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);
  const isAdServerMarket = useSelector(getIsAdServer);

  const onComplete = () => {
    dispatch(
      getDealDetails(
        dealId,
        cancelFunctions,
        navigate,
        config,
        hasAdsDealLevelCPMEnabled,
        hasNonGuaranteedExtendedTargetEnabled,
        isAdServerMarket,
      ),
    );
  };

  return (
    <div ref={forwardedRef} className="bg-neutral-50 py-2.5 px-4 mb-16 rounded-xl" id={links.commonDeal.id}>
      <div className="flex justify-end items-center space-x-4">
        <StatusRenderer
          value={bookingStatusCode}
          statuses={DEAL_STATUS_BADGE}
          direction={TooltipDirection.TOP}
          badgeSize={BadgeSize.NORMAL}
        />

        {!isDealFormOpen ? (
          <div className="flex-grow grid grid-cols-4 gap-x-4 items-center" data-test-id="deal-form-hidden">
            <span
              className="sub-header-base text-neutral-950 overflow-ellipsis truncate"
              data-test-id="deal-form-hidden-deal-name"
            >
              {dealName}
            </span>
            <div className="overflow-ellipsis truncate text-neutral-600">
              <SVG className="inline-block w-4 mr-2.5" src={userSvg} />
              <span className="body-base align-middle" data-test-id="deal-form-hidden-advertiser">
                {advertiser?.name}
              </span>
            </div>
            <div className="overflow-ellipsis truncate text-neutral-600">
              <SVG className="inline-block w-4 mr-2.5" src={clipboardSvg} />
              <span className="body-base align-middle" data-test-id="deal-form-hidden-deal-type">
                {getDealTypeLabel(dealType)}
              </span>
            </div>
            {!isAdServerMarket || isCpmCampaignLevel ? (
              <div className="overflow-ellipsis truncate text-neutral-600">
                <SVG className="inline-block w-4 mr-2.5" src={moneySvg} />
                <span className="body-base align-middle" data-test-id="deal-form-hidden-cpm">
                  {formatCurrency({ value: cpm, currencyCode, localeCode })}
                </span>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="flex-none flex items-center">
          {expirationDate && <CountdownTimer key={expirationDate} date={expirationDate} onComplete={onComplete} />}
          <Button
            btnType={ButtonType.TRANSPARENT}
            btnSize={ButtonSize.SMALL}
            onClick={() => dispatch(toggleDealFormOpen())}
            dataTestId="toggle-form-button"
          >
            <SVG
              className={`text-neutral-600 w-3 ${isDealFormOpen ? 'transform -rotate-180' : ''}`}
              src={chevronDownSvg}
            />
            <SVG className="text-neutral-600 w-4" src={editSvg} />
          </Button>
        </div>
      </div>

      {isDealFormOpen ? (
        <form className="pt-10 pb-6 px-11 text-red" data-test-id="deal-form-open">
          <Card
            icon={bullhornSvg}
            header="Deal name"
            info="Add your deal name for a future easy searching"
            cardType={CardType.TRANSPARENT}
            cardTheme={CardTheme.LIGHT}
          >
            <DealName />
          </Card>
          <hr className="border-t border-neutral-300 mt-6 mb-10" />
          <Card
            icon={comparisonSvg}
            header="Deal type"
            info="Select your deal type to setup the deal"
            cardType={CardType.TRANSPARENT}
            cardTheme={CardTheme.LIGHT}
          >
            <DealTypes />
          </Card>
          <hr className="border-t border-neutral-300 mt-6 mb-10" />
          <Card
            icon={infoSvg}
            header="Deal info"
            info="Provide information regarding your deal"
            cardType={CardType.TRANSPARENT}
            cardTheme={CardTheme.LIGHT}
          >
            <DealInfo />
          </Card>
          {hasAdsDealLevelCPMEnabled || !isAdServerMarket ? <AdsDealLevelCpm /> : null}
        </form>
      ) : null}
    </div>
  );
};

export default forwardRef(Deal);
