import { createSelector } from '@reduxjs/toolkit';
import { environment } from 'config';

const liveboardIds = {
  uat: {
    publisher: '5c3f305e-96cb-4f9e-89e3-ac5a1b3b16ab',
  },
  prd: { publisher: '966faff4-680a-4464-8164-80b42811fce3' },
  dev: { publisher: '65d951df-8046-462f-8e9c-617cdee5d8ed' },
  local: { publisher: '65d951df-8046-462f-8e9c-617cdee5d8ed' },
};

export const getProofOfPlayLiveboardId = createSelector(() => {
  if (environment === 'uat' || environment === 'prd' || environment === 'dev' || environment === 'local') {
    return liveboardIds[environment].publisher;
  }

  return 'invalid-liveboard-id';
});
