import Chips from 'components/patterns/Chips';
import MultiSelect from 'components/patterns/MultiSelect';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import { MediaOwnerOption } from './MediaOwner.types';
import { useDsp } from './utils';

export const MediaOwner: FunctionComponent<{ label?: string }> = ({ label }) => {
  const dispatch = useDispatch();
  const { transformedAvailableMediaOwners, defaultMediaOwners } = useDsp();

  const mediaOwners: string[] = useSelector(
    (state: RootState) => state.dealManagement.commonDeal.currentLine.mediaOwners,
  );

  const [mediaOwnersSelected, setMediaOwnersSelected] = useState<MediaOwnerOption[]>([]);

  const onAddMediaOwner = (selection: MediaOwnerOption[]): void => {
    dispatch(changeDealCurrentLineData({ mediaOwners: [...selection.map(({ name }) => name)] }));
  };

  const onRemoveAllMediaOwners = (): void => {
    dispatch(changeDealCurrentLineData({ mediaOwners: [] }));
  };

  const onRemoveMediaOwner = (selection: MediaOwnerOption): void => {
    const newMediaOwners = mediaOwnersSelected.filter(({ code }) => code !== selection.code).map(({ name }) => name);
    dispatch(changeDealCurrentLineData({ mediaOwners: newMediaOwners }));
  };

  useEffect(() => {
    setMediaOwnersSelected(mediaOwners?.map((mo) => ({ code: mo, name: mo })) || []);
  }, [mediaOwners]);

  useEffect(() => {
    setMediaOwnersSelected(defaultMediaOwners);
    dispatch(changeDealCurrentLineData({ mediaOwners: [...defaultMediaOwners.map(({ name }) => name)] }));
  }, [defaultMediaOwners]);

  return (
    <div data-test-id="media-owner">
      {label && <div className="sub-header-base text-neutral-950-opacity-60 mb-2.5">{label}</div>}
      <div className="flex flex-row">
        <div className="mr-3">
          <MultiSelect
            dataTestId="media-owner-input"
            items={transformedAvailableMediaOwners}
            selectedItems={mediaOwnersSelected}
            onSelect={onAddMediaOwner}
            onClearAll={onRemoveAllMediaOwners}
            isDisabled={false}
          />
        </div>
        {mediaOwnersSelected.length > 0 && (
          <Chips
            dataList={mediaOwnersSelected}
            dataTestId="selected-media-owner"
            idKey="code"
            labelKey="name"
            onRemove={onRemoveMediaOwner}
            isClearAllVisible={false}
          />
        )}
      </div>
    </div>
  );
};
