import { useMemo, useState } from 'react';
import { isArray } from 'lodash';
import excludeSvg from 'assets/icons/exclude.svg';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import SectionButtonChip from '../SectionButtonChip/SectionButtonChip';
import { ChipColors } from '../SectionButtonChip/SectionButtonChip.types';
import { ListProps, SectionButtonChipListProps } from './SectionButtonChipList.types';

const ExpandedList: React.FC<ListProps> = ({ category, chips, isValueTruncated, isExcluded }) => {
  return (
    <>
      <SectionButtonChip
        label={category}
        value={chips[0].name}
        isValueTruncated={isValueTruncated}
        labelStyle={isExcluded ? ChipColors.LIGHT_GREY : ChipColors.LIGHT_PURPLE}
        valueStyle={isExcluded ? ChipColors.DARK_GREY : ChipColors.DARK_PURPLE}
        icon={isExcluded ? excludeSvg : null}
      />
      {chips.slice(1).map((chip) => (
        <SectionButtonChip
          key={chip.name}
          label={chip.name}
          labelStyle={isExcluded ? ChipColors.DARK_GREY : ChipColors.DARK_PURPLE}
          isValueTruncated={isValueTruncated}
        />
      ))}
    </>
  );
};

const CollapsedList: React.FC<ListProps> = ({ category, chips, isExcluded }) => {
  const [isTooltip, setIsTooltip] = useState(false);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div onMouseOver={() => setIsTooltip(true)} onMouseOut={() => setIsTooltip(false)}>
      <Popover
        isOpen={isTooltip}
        align="center"
        positions={['bottom']}
        onClickOutside={() => setIsTooltip(false)}
        containerClassName="z-50"
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="var(--neutral-950)"
            arrowSize={8}
          >
            <div className="flex flex-col gap-y-1 bg-neutral-950 text-neutral-50 rounded-md px-4 py-2">
              {chips.map((value) => (
                <span key={value.name}>{value.name}</span>
              ))}
            </div>
          </ArrowContainer>
        )}
      >
        <div>
          <SectionButtonChip
            icon={isExcluded ? excludeSvg : null}
            label={category}
            value={chips.length.toString()}
            labelStyle={isExcluded ? ChipColors.LIGHT_GREY : ChipColors.LIGHT_PURPLE}
            valueStyle={isExcluded ? ChipColors.DARK_GREY : ChipColors.DARK_PURPLE}
          />
        </div>
      </Popover>
    </div>
  );
};

const SectionButtonChipList: React.FC<SectionButtonChipListProps> = ({ selected, maxSelected = 5 }) => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const content = useMemo(() => {
    if (isArray(selected)) return selected.map((value) => <SectionButtonChip key={value} label={value} />);

    return Object.keys(selected).map((category) => {
      const chips = selected[category];
      if (isArray(chips)) {
        if (chips.length <= maxSelected) return <ExpandedList key={category} category={category} chips={chips} />;

        return <CollapsedList key={category} category={category} chips={chips} />;
      }

      const { included, excluded } = chips;
      const includedExcludedList: JSX.Element[] = [];

      if (included)
        if (included.length <= maxSelected) {
          includedExcludedList.push(<ExpandedList key={`${category}-included`} category={category} chips={included} />);
        } else
          includedExcludedList.push(
            <CollapsedList key={`${category}-included`} category={category} chips={included} />,
          );

      if (excluded)
        if (excluded.length <= maxSelected) {
          includedExcludedList.push(<ExpandedList key={category} category={category} chips={excluded} isExcluded />);
        } else
          includedExcludedList.push(<CollapsedList key={category} category={category} chips={excluded} isExcluded />);

      return includedExcludedList;
    });
  }, [selected]);

  return <div className="flex w-full flex-col align-middle items-end gap-1">{content}</div>;
};

export default SectionButtonChipList;
