import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import infoSvg from 'assets/icons/info.svg';
import Button, { Color } from 'lib/Button';
import Modal from 'lib/Modal';
import { getIsAdvertiserInfoChanged } from 'store/dealManagement/selectors';
import { Store } from 'components/common/types/Store.types';
import { AdvertiserInfoConfirmationModalProps } from './AdvertiserInfoConfirmationModal.types';

const AdvertiserInfoConfirmationModal: React.FC<AdvertiserInfoConfirmationModalProps> = ({
  isVisible,
  onConfirm,
  onUndoChanges,
}) => {
  const advertiser = useSelector((state: Store) => state.dealManagement.commonDeal.advertiser);
  const brand = useSelector((state: Store) => state.dealManagement.commonDeal.brand);
  const productCategory = useSelector((state: Store) => state.dealManagement.commonDeal.productCategory);
  const agency = useSelector((state: Store) => state.dealManagement.commonDeal.agency);
  const specialist = useSelector((state: Store) => state.dealManagement.commonDeal.specialist);
  const backupAdvertiser = useSelector((state: Store) => state.dealManagement.backupFormData.commonDeal.advertiser);
  const backupBrand = useSelector((state: Store) => state.dealManagement.backupFormData.commonDeal.brand);
  const backupProductCategory = useSelector(
    (state: Store) => state.dealManagement.backupFormData.commonDeal.productCategory,
  );
  const backupAgency = useSelector((state: Store) => state.dealManagement.backupFormData.commonDeal.agency);
  const backupSpecialist = useSelector((state: Store) => state.dealManagement.backupFormData.commonDeal.specialist);
  const { isAdvertiserChanged, isBrandChanged, isProductCategoryChanged, isAgencyChanged, isSpecialistChanged } =
    useSelector(getIsAdvertiserInfoChanged);

  const modalContent = useMemo(() => {
    if (!isVisible) return '';

    const advertiserChange =
      isAdvertiserChanged && `The Advertiser from ${backupAdvertiser?.name} to ${advertiser?.name}`;

    const brandChange = isBrandChanged && `The Brand from ${backupBrand?.name} to ${brand?.name}`;

    const productCategoryChange =
      isProductCategoryChanged &&
      `The Product category from ${backupProductCategory?.name} to ${productCategory?.name}`;

    const agencyChange =
      isAgencyChanged && `The Agency from ${backupAgency?.name || '"none"'} to ${agency?.name || '"none"'}`;

    const specialistChange =
      isSpecialistChanged &&
      `The Specialist from ${backupSpecialist?.name || '"none"'} to ${specialist?.name || '"none"'}`;

    const changes = [advertiserChange, brandChange, productCategoryChange, agencyChange, specialistChange].filter(
      Boolean,
    );

    return (
      <>
        You are changing:
        <ul className="list-disc list-inside mt-1 mb-2">
          {changes.map((change, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{change}</li>
          ))}
        </ul>
        This may impact your availability. Please confirm to proceed?
      </>
    );
  }, [isVisible]);

  return (
    <Modal
      dataTestId="advertiser-info-confirmation-modal"
      isOpen={isVisible}
      onClose={onUndoChanges}
      icon={<SVG src={infoSvg} className="text-pinkRed-500" />}
      title="Save changes"
      actionButtons={
        <>
          <Button onClick={onUndoChanges} label="Undo changes" color={Color.DANGER} />
          <Button onClick={onConfirm} label="Confirm" color={Color.PRIMARY} />
        </>
      }
    >
      <div className="ml-8 body-base text-neutral-950-opacity-60">{modalContent}</div>
    </Modal>
  );
};

export default AdvertiserInfoConfirmationModal;
