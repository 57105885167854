import cx from 'classnames';
import SVG from 'react-inlinesvg';
import { ChipColors, IconStyle, SectionButtonChipProps } from './SectionButtonChip.types';

const SectionButtonChip: React.FC<SectionButtonChipProps> = ({
  dataTestId,
  label,
  value,
  icon,
  labelStyle = ChipColors.LIGHT_PURPLE,
  valueStyle = ChipColors.DARK_PURPLE,
  iconStyle = IconStyle.DARK_GREY,
  isValueTruncated = true,
}) => {
  return (
    <div data-test-id={dataTestId} className={cx('flex rounded-full max-w-[200px]', labelStyle)}>
      <div className="px-2 py-0.5 gap-0.5 flex justify-center items-center max-w-[170px]">
        {!!icon && <SVG className={cx('shrink-0 w-4 h-4', iconStyle)} src={icon} />}
        <span className={cx('text-sm whitespace-nowrap truncate')}>{label}</span>
      </div>
      {value ? (
        <span
          className={cx('rounded-full px-2 py-0.5 text-sm', valueStyle, {
            truncate: isValueTruncated,
          })}
        >
          {value}
        </span>
      ) : null}
    </div>
  );
};

export default SectionButtonChip;
