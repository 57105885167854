export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  icon?: React.SVGProps<SVGSVGElement>;
  size?: ModalSize;
  children: React.ReactNode;
  actionButtons?: React.ReactNode;
  dataTestId?: string;
}

export enum ModalSize {
  small = 'small',
  large = 'large',
}
