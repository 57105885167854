import { createSelector } from '@reduxjs/toolkit';
import { environment } from 'config';

const liveboardIds = {
  uat: {
    publisher: '119cba27-3e47-49ce-9a68-620818a51103',
  },
  prd: { publisher: '119cba27-3e47-49ce-9a68-620818a51103' },
};

export const getYieldInsightsLiveboardId = createSelector(() => {
  if (environment === 'uat' || environment === 'prd') {
    return liveboardIds[environment].publisher;
  }

  return 'invalid-liveboard-id';
});
