import React from 'react';
import Chip from 'lib/Chip';
import { Color, Size } from 'lib/Chip/Chip.types';
import { DealStatus } from 'components/common/types/Deal.types';

const bookingStatusStyles: Partial<Record<DealStatus, { label: string; color: Color }>> = {
  [DealStatus.PENDING_APPROVAL]: {
    label: 'Pending',
    color: Color.YELLOW,
  },
  [DealStatus.APPROVED]: {
    label: 'Approved',
    color: Color.PRIMARY,
  },
  [DealStatus.CANCELLED]: {
    label: 'Cancelled',
    color: Color.RED,
  },
  [DealStatus.REJECTED]: {
    label: 'Rejected',
    color: Color.RED,
  },
  [DealStatus.LIVE]: {
    label: 'Live',
    color: Color.SKY_BLUE,
  },
  [DealStatus.ENDED]: {
    label: 'Ended',
    color: Color.DEFAULT,
  },
} as const;

const defaultBookingStatusStyle = { label: 'New', color: Color.DEFAULT };

interface ChipBookingStatusProps {
  bookingStatus: DealStatus;
}

export const ChipBookingStatus: React.FC<ChipBookingStatusProps> = ({ bookingStatus }) => {
  const styles = bookingStatusStyles[bookingStatus] ?? defaultBookingStatusStyle;

  return <Chip label={styles.label} color={styles.color} size={Size.SMALL} dataTestId="chip-booking-status" />;
};
