import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getMetaData, getDealConfig } from 'modules/api/deal';
import DealWithRightSidebar from 'components/common/layout/DealWithRightSidebar';
import Panel from 'components/common/Deal/Panel';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { getDealDetails } from 'store/dealManagement/actions';
import { changeMeta } from 'store/dealManagement/reducer';
import { getIsAdServer } from 'store/publisher/selectors';
import { updateDealConfig } from 'store/dealConfig/reducer';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import RightSidebar from '../common/RightSidebar';
import DealHeader from '../common/DealHeader';
import Main from './Main';

const DealLines = () => {
  const { internalId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasAdsDealLevelCPMEnabled = useHasFeatureAccess(FeatureFlags.ADS_DEAL_LEVEL_CPM);
  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);
  const isAdServerMarket = useSelector(getIsAdServer);
  const cancelFunctions = useCancelRequest();
  const dealConfig = useSelector((state) => state.dealConfig);

  useEffect(() => {
    const getMeta = async () => {
      try {
        const metaData = await getMetaData({ cancelFunctions });
        dispatch(changeMeta(metaData));
      } catch {} // eslint-disable-line no-empty
    };

    getMeta();
  }, []);

  useEffect(() => {
    const getDealConfigData = async () => {
      try {
        const config = await getDealConfig(cancelFunctions);

        dispatch(updateDealConfig({ ...config, isAdServer: false }));
      } catch {
        // eslint-disable-next-line no-console
        console.error('Failed fetching deal config for the automation market');
      }
    };

    if (!dealConfig.isDealConfigFetched) {
      getDealConfigData();
    }
  }, [dealConfig.isDealConfigFetched]);

  useEffect(() => {
    if (internalId && dealConfig.isDealConfigFetched) {
      dispatch(
        getDealDetails(
          internalId,
          cancelFunctions,
          navigate,
          dealConfig,
          hasAdsDealLevelCPMEnabled,
          hasNonGuaranteedExtendedTargetEnabled,
          isAdServerMarket,
        ),
      );
    }
  }, [internalId, cancelFunctions, dealConfig.isDealConfigFetched]);

  return (
    <DealWithRightSidebar
      sidebar={<RightSidebar />}
      header={<DealHeader />}
      leftContent={<Panel />}
      rightContent={<Main />}
    />
  );
};

export default DealLines;
