import closeSvg from 'assets/icons/close.svg';
import cx from 'classnames';
import SVG from 'react-inlinesvg';
import { ChipProps, Color, Size } from './Chip.types';
import { getChipStyles } from './getChipStyles';

const Chip = ({
  label,
  subLabel,
  color = Color.DEFAULT,
  size = Size.MEDIUM,
  icon = undefined,
  hasIndicator = false,
  isRemovable,
  onRemove,
  isDisabled,
  dataTestId,
}: ChipProps): React.ReactElement => {
  const {
    wrapperStyles,
    iconAndLabelWrapperStyles,
    iconStyles,
    labelStyles,
    subLabelStyles,
    removeButtonStyles,
    indicatorStyles,
  } = getChipStyles(isDisabled ? Color.DISABLED : color, size);

  return (
    <div className={cx(wrapperStyles, 'flex items-center group max-w-fit')} data-test-id={dataTestId}>
      <div className={cx(iconAndLabelWrapperStyles, 'py-1 flex justify-center items-center')}>
        {!!icon && <SVG className={iconStyles} src={icon} />}
        <p
          aria-label={label}
          className={cx(labelStyles, 'max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis')}
        >
          {label}
        </p>
      </div>
      {!!subLabel && (
        <div className={cx(subLabelStyles, 'h-full flex items-center justify-center text-center')}>
          <p className="max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">{subLabel}</p>
        </div>
      )}
      {isRemovable && (
        <button
          aria-label="Remove chip"
          type="button"
          className={cx(removeButtonStyles, 'flex justify-center items-center mx-1.5')}
          onClick={!isDisabled ? () => onRemove?.(label) : () => null}
          data-test-id={`chip-remove-${label}`}
        >
          <SVG src={closeSvg} />
        </button>
      )}
      {hasIndicator ? (
        <div data-test-id="chip-indicator" className={cx(indicatorStyles, 'mx-1.5 rounded-full')} />
      ) : null}
    </div>
  );
};

export default Chip;
