import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import IconButton from 'lib/IconButton';
// import Button, { Color, Size as SizeButton } from 'lib/Button';
import sidebarSimpleLeftIcon from 'assets/icons/sidebar-simple-left.svg';
import sidebarSimpleRightIcon from 'assets/icons/sidebar-simple-right.svg';
// import locationsIcon from 'assets/icons/locations.svg';
import {
  changeCurrentLine,
  changeDealData,
  createNewLineWithUniqueName,
  removeTemporaryDealId,
} from 'store/dealManagement/reducer';
import { usePlannerHeaderLineContainer } from 'components/pages/Planner/hooks/usePlannerHeaderLineContainer';
import { Store } from 'components/common/types/Store.types';
import { Line } from 'components/common/types/Deal.types';
import { IconTradingType } from './IconTradingType';
import { ChipBookingStatus } from './ChipBookingStatus';
import { DealLines } from './DealLines';

const DealHeaderUnified: React.FC = () => {
  const dispatch = useDispatch();
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const dealName = useSelector((state: Store) => state.dealManagement.commonDeal.dealName);
  const campaignType = useSelector((state: Store) => state.dealManagement.campaignType);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const currentDealSummary = useSelector((state: Store) => state.dealManagement.commonDeal.summary);
  const { headerContainerRef, displayDealLinesDropdown, displayedLines, allLinesSaved, hasReachedMaxLines } =
    usePlannerHeaderLineContainer();

  const onNewLineClick = (): void => {
    dispatch(createNewLineWithUniqueName());
  };

  const onLineSelect = (line: Line): void => {
    dispatch(changeCurrentLine(line.id));
    dispatch(removeTemporaryDealId());
    dispatch(changeDealData({ summary: currentDealSummary }));
  };

  return (
    <header className="p-2 bg-neutral-50 border border-neutral-950-opacity-10 flex items-center">
      <div className="flex flex-shrink-0 gap-2 pr-1 items-center border-r border-neutral-950-opacity-10">
        <IconTradingType tradingType={campaignType} />
        <h2
          data-test-id="deal-header-deal-name"
          className={cx('header-base w-56 truncate', {
            'text-essential-tertiary italic': isNewDeal,
            'text-essential-primary': !isNewDeal,
          })}
        >
          {isNewDeal ? 'New campaign' : dealName}
        </h2>
        <ChipBookingStatus bookingStatus={bookingStatusCode} />
        <IconButton icon={sidebarSimpleLeftIcon} name="Toggle left sidebar" />
      </div>
      <div
        data-test-id="unified-header-lines-list"
        className="whitespace-nowrap flex flex-1 align-items-center space-x-2 px-2"
        ref={headerContainerRef}
      >
        <DealLines
          displayedLines={displayedLines}
          displayDealLinesDropdown={displayDealLinesDropdown}
          isAddNewLineDisabled={!allLinesSaved || hasReachedMaxLines}
          isSelectLineDisabled={!allLinesSaved}
          onLineSelect={onLineSelect}
          onNewLineClick={onNewLineClick}
          addNewLineLabel="New deal line"
        />
      </div>
      <div className="flex flex-shrink-0 gap-1 w-[341px] justify-end items-center border-l border-neutral-950-opacity-10">
        {/* Hidden until switching view functionality is enabled */}
        {/* <Button color={Color.BLACK_AND_WHITE} size={SizeButton.SMALL} svg={locationsIcon} label="Open in planner" /> */}
        <IconButton icon={sidebarSimpleRightIcon} name="Toggle right sidebar" />
      </div>
    </header>
  );
};

export default DealHeaderUnified;
