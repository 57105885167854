import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import debounce from 'lodash/debounce';
import { changeDealData } from 'store/dealManagement/reducer';
import { BaseInput } from 'lib/BaseInput';
import { DealStatus } from 'components/common/types/Deal.types';
import { InputSize } from 'components/common/types/Input.types';
import { isReadOnly } from 'utils/isReadOnly';
import { ShadowStyle } from 'consts/shadow';

const DealName = () => {
  const dispatch = useDispatch();
  const isEditingDisabled = useSelector((state) => state.dealManagement.isEditingDisabled);
  const dealId = useSelector((state) => state.dealManagement.commonDeal.dealId);
  const sbId = useSelector((state) => state.dealManagement.commonDeal.sbId);
  const dealName = useSelector((state) => state.dealManagement.commonDeal.dealName);
  const bookingStatusCode = useSelector((state) => state.dealManagement.commonDeal.bookingStatusCode);

  const [dealNameState, setDealNameState] = useState(dealName);

  const changeDealNameDebounced = useCallback(
    debounce((newDealName) => dispatch(changeDealData({ dealName: newDealName })), 1000),
    [changeDealData],
  );
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled) && !isPendingReservation;

  useEffect(() => {
    setDealNameState(dealName);
  }, [dealName]);

  return (
    <div className={classNames('flex', ShadowStyle.SMALL)}>
      <div className="flex-1">
        <BaseInput
          size={InputSize.LARGE}
          name="dealName"
          value={dealNameState}
          onChange={(e) => {
            setDealNameState(e.target.value);
            changeDealNameDebounced(e.target.value);
          }}
          placeholder="Type here..."
          disabled={readOnly}
          dataTestId="input-side-content-deal-name"
          isSideContent={!!dealId || !!sbId}
          endComponent={
            dealId || sbId ? (
              <div className="h-11 pl-2 flex flex-col justify-center text-neutral-900 border-0 border-l border-neutral-950-opacity-20">
                {dealId ? (
                  <p className="whitespace-nowrap flex justify-between space-x-3">
                    <span className="body-sm">Deal ID:</span>
                    <span className="sub-header-sm">{dealId}</span>
                  </p>
                ) : null}
                {sbId ? (
                  <p className="whitespace-nowrap flex justify-between space-x-3">
                    <span className="body-sm">SB ID:</span>
                    <span className="sub-header-sm">{sbId}</span>
                  </p>
                ) : null}
              </div>
            ) : null
          }
        />
      </div>
    </div>
  );
};

export default DealName;
