import ReactModal from 'react-modal';
import SVG from 'react-inlinesvg';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import closeSvg from 'assets/icons/close.svg';

import { ModalProps } from './Modal.types';

export const ModalSizes = {
  small: 'w-9/12 md:w-6/12 lg:w-125',
  large: 'w-11/12 xl:w-300',
};

const Modal = ({
  isOpen,
  onClose,
  title = undefined,
  icon = undefined,
  size,
  children,
  actionButtons = undefined,
  dataTestId = undefined,
}: ModalProps): React.ReactElement => {
  const modalSize = size ? ModalSizes[size] : '';

  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={onClose}
      overlayClassName="bg-modalOverlay-dark fixed inset-x-0 inset-y-0 z-50"
      className={`bg-neutral-50 absolute transform -translate-y-2/4 -translate-x-2/4 top-1/2 left-1/2 rounded-md ${modalSize}`}
    >
      <div className="flex max-h-popup" data-test-id={dataTestId}>
        <div className="absolute -right-3 -top-3">
          <Button
            dataTestId="close-modal-button"
            onClick={onClose}
            btnShape={ButtonShape.CIRCLE}
            btnType={ButtonType.CLOSE}
          >
            <SVG src={closeSvg} className="h-5 w-4" />
          </Button>
        </div>
        <div className="flex-col flex-1 space-y-4 m-10">
          {icon || title ? (
            <header className="flex items-center space-x-4" data-test-id="header">
              {icon ? <div>{icon}</div> : null}
              {title ? <h3 className="sub-header-lg text-neutral-900">{title}</h3> : null}
            </header>
          ) : null}
          <div className="overflow-auto max-h-popupContent leading-normal flex flex-1">{children}</div>
          {actionButtons ? <div className="flex justify-end space-x-3 pt-3">{actionButtons}</div> : null}
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
