import { createSelector } from '@reduxjs/toolkit';
import { getIsReseller } from 'store/publisher/selectors';
import { environment } from 'config';

const liveboardIds = {
  uat: {
    publisher: '276282e7-c60a-4c18-b4e7-13746616aad7',
    reseller: '17a46a4d-598b-44fe-b4d8-d6042e69ab5d',
  },
  prd: {
    publisher: '5d6c2ec0-c183-4f58-9ab6-605eb91bff95',
    reseller: '6d7a0736-ca77-4021-a156-74e8b1a1b1a0',
  },
};

export const getDealHealthLiveboardId = createSelector(getIsReseller, (isReseller) => {
  if (environment === 'uat' || environment === 'prd') {
    return isReseller ? liveboardIds[environment].reseller : liveboardIds[environment].publisher;
  }

  return 'invalid-liveboard-id';
});
