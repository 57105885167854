import { CodeNameModel } from 'components/common/types';

export enum SectionButtonTheme {
  SOLID,
  DASHED,
}

export enum SectionButtonRounded {
  NONE = 'rounded-none',
  DEFAULT = 'rounded-md',
  ROUNDED_TOP = 'rounded-t-md',
  ROUNDED_BOTTOM = 'rounded-b-md',
}

export enum SectionButtonShadow {
  DEFAULT = 'shadow-inner-sm',
  NONE = 'shadow-none',
}

export const ButtonThemes = {
  [SectionButtonTheme.SOLID]: {
    textColor: 'text-neutral-950',
    iconColor: 'text-neutral-600',
    buttonContainer: 'border-neutral-950-opacity-10',
    inactive: 'bg-white',
  },
  [SectionButtonTheme.DASHED]: {
    textColor: 'text-primary-600',
    iconColor: 'text-primary-600',
    buttonContainer: 'border-primary-600 disabled:border-neutral-500 disabled:text-neutral-500',
    inactive: 'border-dashed',
  },
};

export type SectionSelectedType =
  | { [key: string]: { included?: CodeNameModel[]; excluded?: CodeNameModel[] } }
  | { [key: string]: CodeNameModel[] }
  | string[];

export interface SectionButtonProps {
  theme: SectionButtonTheme;
  dataTestId?: string;
  icon: string;
  label: string;
  isActive?: boolean;
  isDisabled?: boolean;
  onClear?: VoidFunction;
  selected?: SectionSelectedType;
  isRequired?: boolean;
  onClick: () => void;
  rounded?: SectionButtonRounded;
  shadow?: SectionButtonShadow;
  areChipsAutoCollapsed?: boolean;
  isValueTruncated?: boolean;
}
